<div class="fixed-footer" id="footer">
  <div class="container">
    <div class="footer">
      <ul class="list-unstyled main-footer-ul d-flex flex-wrap">
        <li>
          <div class="brand-logo brand-footer cursor-scale">
            <img [src]="env.google.bucket + '/assets/agingplan/logo/AgingPlan_DarkBackground.svg'" alt="AgingPlan Logo" />
          </div>
        </li>
        <li>
          <ul class="list-unstyled">
            <li>
              <strong> MAIL </strong>
            </li>

            <li class="cursor-scale small">
              816 W Francis Ave
              <br />
              #482
              <br />
              Spokane, WA. 99205
            </li>
          </ul>
        </li>
        <li>
          <ul class="list-unstyled">
            <li>
              <strong> LINKS </strong>
            </li>

            <li>
              <a href="/privacy-policy" class="footer-link-ul cursor-scale small"> Privacy Policy </a>
            </li>
            <li>
              <a href="/terms-and-conditions" class="footer-link-ul cursor-scale small"> Terms &amp; Conditions </a>
            </li>
            <li>
              <a href="/cookie-policy" class="footer-link-ul cursor-scale small"> Cookie Policy </a>
            </li>
            <li>
              <a [routerLink]="['/forms']">Forms</a>
            </li>
            <!-- <li>
              <a href="javascript:void(0)" (click)="popups.openPopup('healthQuestionnairePopup')">
                Health Questionnaire
              </a>
            </li> -->
          </ul>
        </li>

        <li>
          <ul class="list-unstyled">
            <li>
              <strong> CONNECT </strong>
            </li>
            <li>
              <a href="tel:{{commonService.footer_disclosure?.phone ? commonService.footer_disclosure?.phone : '5097447065'}}" class="footer-link-ul cursor-scale small">Phone: {{ commonService.footer_disclosure?.phone ? commonService.footer_disclosure?.phone : '509 744 7065' }} </a>
            </li>
            <li>
              <a href="mailto:{{commonService.footer_disclosure?.email ? commonService.footer_disclosure?.email : 'info@agingplan.com'}}" class="footer-link-ul cursor-scale small"> {{ commonService.footer_disclosure?.email ? commonService.footer_disclosure?.email : 'info@agingplan.com' }} </a>
            </li>
          </ul>
        </li>
      </ul>

      <hr />
      <p class="disclosure" *ngIf="commonService.footer_disclosure?.disclosure" [innerText]="commonService.footer_disclosure?.disclosure"></p>
      <p class="copyright cursor-scale small">&copy; 2005-{{ year }} AGINGPLAN, ALL RIGHTS RESERVED</p>
    </div>
  </div>
</div>
